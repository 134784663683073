import React, { useMemo } from 'react'
import { EventsTable } from '../component/EventsTable'
import { Page } from '../component/Page'
import { API } from '../API'
import { useQuery } from 'react-query'
import LoadingSpinner from '../component/LoadingSpinner'
import getTodayNextEvents from '../helpers/getTodayNextEvents'
import { Box, Text } from '@chakra-ui/react'

export const IndexPage = () => {
    const { data, isError, isLoading } = useQuery('events', API.getEvents, {
        retry: false
    })

    const [todayEvents, nextEvents] = useMemo(() => {
        if (data) return getTodayNextEvents(data)
        else return [[], []]
    }, [data])

    return (
        <>
            <Page
                header={{
                    content: (
                        <>
                            <Text as="h2" color="white">Сегодня</Text>
                            {isLoading && (
                                <LoadingSpinner />
                            )}
                            {isError && (
                                <Text as="h4" color="white">-- Не удалось загрузить события, попробуйте перезагрузить
                                                            страницу --</Text>
                            )}
                            {!!data && (
                                <>
                                    {todayEvents?.length
                                        ? <EventsTable data={todayEvents} />
                                        : <Text as="h4" color="white">-- Нет событий --</Text>
                                    }
                                </>
                            )}
                        </>
                    )
                }}
            >
                <Box layerStyle="block">
                    <Box layerStyle="content">
                        <Text as="h2" color="base">Предстоящие</Text>
                        {isLoading && (
                            <LoadingSpinner />
                        )}
                        {isError && (
                            <Text as="h4">-- Не удалось загрузить события, попробуйте перезагрузить
                                          страницу --</Text>
                        )}
                        {!!data && (
                            <>
                                {nextEvents?.length
                                    ? <EventsTable data={nextEvents} />
                                    : <Text as="h4">-- Нет событий --</Text>
                                }
                            </>
                        )}

                    </Box>
                </Box>
            </Page>
        </>

    )
}
