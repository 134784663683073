import { Form, Formik } from 'formik'
import { withFormikField } from '../HOCs/withFormikField'
import { InputField } from './InputField'
import * as Yup from 'yup'
import { Box, Flex, Grid, IconButton, Text, useBreakpointValue } from '@chakra-ui/react'
import OrganizationField from './OrganizationField'
import Button from './Button'
import AddIcon from './icons/AddIcon'
import { useEffect, useState } from 'react'
import { CloseIcon } from '@chakra-ui/icons'

const validationSchema = Yup.object().shape({
    participants: Yup.array().of(Yup.object().shape({
        name: Yup.string().required('Обязательное поле'),
        email: Yup.string().email('Введите верный e-mail').required('Обязательное поле'),
        phone: Yup.string().required('Обязательное поле'),
        position: Yup.string().required('Обязательное поле')
    })),
    company: Yup.string().required('Обязательное поле'),
})

export const RegistrationForm = ({ onSubmit = null, onSubmitted = null, isLoading = false, initialValues }) => {
    const Input = withFormikField(InputField)
    const OrganizationInput = withFormikField(OrganizationField)
    const gap = useBreakpointValue({ base: '20px' })
    const participantTemplate = { name: '', position: '', phone: '', email: '' }
    const [initialData, setInitialData] = useState({
        company: initialValues?.company || '',
        participants: initialValues?.participants || [participantTemplate]
    })

    useEffect(() => {
        if (initialValues) {
            setInitialData(initialValues)
        }
    }, [initialValues])

    const addParticipant = (data) => {
        setInitialData({
            ...data,
            participants: [...data.participants, participantTemplate]
        })
    }

    const removeParticipant = (data, idx) => {
        setInitialData(() => {
            const newParticipants = data.participants.slice()
            newParticipants.splice(idx, 1)
            return {
                ...data,
                participants: newParticipants
            }
        })
    }

    return (
        <Formik
            initialValues={initialData}
            enableReinitialize
            onSubmit={(values) => {
                onSubmit?.(values)

                try {
                    onSubmitted?.(values)
                } catch (e) {
                    console.log(e)
                }

            }}
            validationSchema={validationSchema}
        >
            {({ values, submitForm }) => (
                <Form>
                    <Grid
                        templateColumns={{ base: '1fr', md: '1fr 1fr' }}
                        gap={gap}
                    >
                        <OrganizationInput name="company" title="Организация*"/>
                    </Grid>
                    {values.participants.map((_, idx) => (
                        <Box mt="30px" key={idx}>
                            <Flex alignItems="center">
                                <Text color="base" fontWeight="bold">Участник {idx + 1}:</Text>
                                {idx !== 0 && (
                                    <IconButton
                                        icon={<CloseIcon width="11px" height="11px"/>}
                                        size="sm"
                                        ml="auto"
                                        onClick={() => removeParticipant(values, idx)}
                                    />
                                )}
                            </Flex>
                            <Grid
                                templateColumns={{ base: '1fr', sm: 'repeat(2, 1fr)', lg: 'repeat(4, 1fr)' }}
                                gap={gap}
                                mt={gap}
                            >
                                <Input name={`participants[${idx}].name`} title="ФИО*"/>
                                <Input name={`participants[${idx}].position`} title="Должность*"/>
                                <Input
                                    name={`participants[${idx}].phone`}
                                    inputMode="numeric"
                                    type="tel"
                                    title="Номер телефона*"
                                />
                                <Input
                                    name={`participants[${idx}].email`}
                                    inputMode="email"
                                    type="email"
                                    title="E-mail*"
                                />
                            </Grid>
                        </Box>
                    ))}
                    <Button
                        mt="20px"
                        leftIcon={<AddIcon width="1em" height="1em"/>}
                        variant="outline"
                        color="base"
                        bg="transparent"
                        borderColor="base"
                        w="100%"
                        _hover={{
                            bg: 'transparent'
                        }}
                        _active={{
                            bg: 'transparent'
                        }}
                        onClick={() => addParticipant(values)}
                    >
                        Добавить участника
                    </Button>
                    <Flex mt="formSubmitButton" justifyContent="center">
                        <Button
                            isLoading={isLoading}
                            loadingText="Отправляем заявку"
                            onClick={submitForm}
                        >
                            Отправить заявку
                        </Button>
                    </Flex>
                </Form>
            )}
        </Formik>
    )
}
