import { extendTheme } from '@chakra-ui/react'

const theme = {
    colors: {
        base: '#EB725A',
        blue: '#445477',
        darkBlue: '#0E1856',
        deepBlue: '#192738',
        gray3: '#828282'
    },
    fonts: {
        body: 'Moscow Sans, sans-serif',
        heading: 'Moscow Sans, sans-serif'
    },
    fontSizes: {
        h3: '1.2rem'
    },
    lineHeights: {
        text: 1.11,
        h1: 1.16,
        h2: 1.16,
        h3: 1.16
    },
    radii: {
        base: '5px'
    },
    space: {
        inputPadding: '16px',
        formSubmitButton: '60px'
    },
    layerStyles: {
        content: {
            maxWidth: '1400px',
            mx: 'auto',
            px: { base: '15px', sm: '20px', lg: '40px', xl: '60px' },
            boxSizing: 'border-box'
        },
        block: {
            mt: '40px'
        }
    },
    textStyles: {
        h1: {
            fontSize: { base: '2rem', sm: '2.5rem', md: '3rem', xl: '4rem' },
            lineHeight: 'h1',
            fontWeight: 600
        },
        h2: {
            fontSize: { base: '1.3rem', sm: '1.6rem', lg: '2rem' },
            lineHeight: 'h2',
            fontWeight: 600
        },
        h3: {
            fontSize: 'h3',
            lineHeight: 'h3',
            fontWeight: 600
        },
        formErrorMessage: {
            marginTop: '3px',
            color: 'red',
            fontSize: '0.77rem'
        }
    },
    styles: {
        global: {
            html: {
                fontSize: '18px',
            },
            body: {
                fontSize: { base: '16px', md: '1rem' },
                lineHeight: 'text',
                color: 'deepBlue'
            },
            h1: {
                textStyle: 'h1'
            },
            h2: {
                textStyle: 'h2',
                mb: '30px'
            },
            h3: {
                textStyle: 'h3'
            },
            mark: {
                bg: 'none',
                color: 'base',
                fontWeight: 600
            }
        }
    }
}

export default extendTheme(theme)
