import { Button as ChakraButton } from '@chakra-ui/react'

const Button = ({ children, disabled = false, ...props }) => {
    return (
        <ChakraButton
            {...style.button}
            _loading={{
                bg: props.bg ?? 'base',
                opacity: 0.5,
                _hover: {
                    bg: props.bg ?? 'base'
                }
            }}
            {...props}
        >
            {children}
        </ChakraButton>
    )
}

const style = {
    button: {
        variant: 'unstyled',
        bg: 'base',
        py: '15px',
        pl: { base: '20px', lg: '40px' },
        pr: { base: '20px', lg: '40px' },
        whiteSpace: 'normal',
        color: 'white',
        borderRadius: 'base',
        height: 'auto',
        fontSize: 'inset',
        display: 'flex',
        alignItems: 'center',
        minW: 'auto'
    }
}

export default Button
