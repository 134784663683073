import axios from 'axios'

const apiUrl = process.env.REACT_APP_API_URL
const eventsUrl = apiUrl + '/events/v1'

export const API = {
    async getEvents () {
        const { data } = await axios.get(eventsUrl)
        return data?.data
    },
    async getEvent (id) {
        const { data } = await axios.get(`${eventsUrl}/${id}`)
        return data?.data
    },
    async registerParticipant (id, data) {
        const { data: d } = await axios.post(`${eventsUrl}/${id}/participants`, data)
        return d
    }
}

setTimeout(() => API.isLoading = false, 5000)

