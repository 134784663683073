import { Field } from './Field'
import { Input } from '@chakra-ui/react'

export const InputField = ({ title, ...props }) => {
    return (
        <Field title={title}>
            <Input
                variant="unstyled"
                color="deepBlue"
                p="inputPadding"
                fontWeight="600"
                _placeholder={{
                    opacity: 0.3,
                    color: 'inherit'
                }}
                {...props}
            />
        </Field>
    )
}
