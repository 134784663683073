import { Box, Text } from '@chakra-ui/react'

export const Field = ({ title, children, ...props }) => {
    return (
        <Box {...props}>
            <Text
                fontSize="16px"
                color="blue"
                mb="13px"
                fontWeight="600"
            >
                {title}
            </Text>
            <Box
                position="relative"
                border="2px solid rgba(14, 24, 86, 0.73)"
                borderRadius="base"
                fontSize="16px"
            >
                {children}
            </Box>
        </Box>

    )
}
