import Header from './Header'
import { motion } from 'framer-motion'
import { Footer } from './Footer'

export const Page = ({ header, footer, children }) => {
    const transition = {
        opacity: {
            duration: 0.4
        },
        y: {
            duration: 0.5
        },
        ease: 'easeInOut'
    }

    const headerAnimation = {
        exit: {
            opacity: 0,
            y: '-100%',
            transition
        },
        enter: {
            opacity: 1,
            y: 0,
            transition
        }
    }

    const contentVariants = {
        exit: {
            opacity: 0,
            y: 200,
            transition
        },
        enter: {
            opacity: 1,
            y: 0,
            transition
        }
    }

    return (
        <>
            {!header?.hide && (
                <motion.div variants={headerAnimation} initial="exit" animate="enter" exit="exit">
                    <Header>{header?.content}</Header>
                </motion.div>
            )}
            <motion.div variants={contentVariants} initial="exit" animate="enter" exit="exit">
                {children}
            </motion.div>

            {!footer?.hide && (
                <Footer />
            )}
        </>

    )
}
