import { useHistory, useParams } from 'react-router-dom'
import React, { useState } from 'react'
import { RegistrationForm } from '../component/RegistrationForm'
import { Page } from '../component/Page'
import { API } from '../API'
import { useQuery } from 'react-query'
import RegistrationSuccessMessage from '../component/RegistrationSuccessMessage'
import { Box, Text, useToast } from '@chakra-ui/react'

const dateFormatter = new Intl.DateTimeFormat('ru', {
    day: '2-digit',
    month: 'long',
    hour: '2-digit',
    minute: '2-digit'
})

export const RegistrationPage = () => {
    const history = useHistory()
    const { id } = useParams()
    const toast = useToast()

    // Получаем данные события или возвращаем на предыдущую страницу при ошибке
    const { data: event, isSuccess } = useQuery(
        ['event', id],
        () => API.getEvent(id),
        {
            enabled: id !== undefined,
            retry: false,
            onError: () => history.goBack()
        })

    const [formSubmitted, setFormSubmitted] = useState(false)
    const [isFormSubmitting, setIsFormSubmitting] = useState(false)
    const [initialFormData, setInitialFormData] = useState(null)

    const handleSubmit = (values) => {
        setIsFormSubmitting(true)
        Promise.all(values.participants.map(participant => {
            const data = {
                ...participant,
                company: values.company
            }
            return API.registerParticipant(id, data)
        }))
            .then(resp => {
                if (resp.every(data => data?.success)) {
                    setFormSubmitted(true)
                } else {
                    const participants = values.participants.slice()
                    resp.forEach((r, idx) => {
                        if (!r?.success) {
                            participants.splice(idx, 1)
                        }
                    })

                    setInitialFormData({
                        ...values,
                        participants
                    })

                    toast({
                        position: 'top-right',
                        status: 'error',
                        title: 'Не удалось зарегистрировать некоторых участников, попробуйте отправить снова',
                        duration: 5000,
                        render: () => (
                            <Box color="white" p={'13px'} bg="#e53e3e" borderRadius={'7px'}>
                                Не удалось зарегистрировать некоторых участников, попробуйте отправить снова
                            </Box>
                        )
                    })
                }
            })
            .finally(() => {
                setIsFormSubmitting(false)
            })
    }

    return (
        <>
            {isSuccess && (
                <Page>
                    <Box layerStyle="content">
                        {!formSubmitted ? (
                            <Box {...style.wrapper}>
                                <Text
                                    as="h2"
                                    textAlign="center"
                                    color="blue"
                                >
                                    Для получения ссылки заполните заявку на участие в совещании «{event.title}»
                                    <br/>
                                    {dateFormatter.format(event.date)}
                                </Text>
                                <Box mt={{ base: '40px', md: '60px', xl: '80px' }}>
                                    <RegistrationForm
                                        initialValues={initialFormData}
                                        onSubmitted={(values) => handleSubmit(values)}
                                        isLoading={isFormSubmitting}
                                    />
                                </Box>
                            </Box>
                        ) : (
                            <Box {...style.wrapper}>
                                <RegistrationSuccessMessage eventId={id}/>
                            </Box>
                        )}
                    </Box>
                </Page>
            )}
        </>
    )
}

const style = {
    wrapper: {
        as: 'section',
        mt: { base: '50px', md: '70px', xl: '100px' },
        mx: 'auto',
        maxW: '900px'
    }
}
