import { AnimatePresence, motion } from 'framer-motion'
import React, { useState } from 'react'
import { Box, chakra, Divider, Flex, Grid, Text } from '@chakra-ui/react'
import getDateComponents from '../helpers/getDateComponents'
import { useQuery } from 'react-query'
import { API } from '../API'
import { CalendarIcon } from '@chakra-ui/icons'
import createGoogleCalendarEventLink from '../helpers/createGoogleCalendarEventLink'
import Button from './Button'

const messageVariants = {
    exit: {
        opacity: 0,
        scale: 0.1,
        transformOrigin: 'center',
        transition: {
            duration: 0.5
        }
    },
    enter: {
        opacity: 1,
        scale: 1,
        transformOrigin: 'center',
        transition: {
            duration: 0.5
        }
    }
}

const MessageWrapper = chakra(motion.section)

function RegistrationSuccessMessage ({ eventId }) {
    const [googleCalendarLink, setGoogleCalendarLink] = useState('')
    const { data: event, isSuccess } = useQuery(
        ['event', eventId],
        () => API.getEvent(eventId)
    )

    createGoogleCalendarEventLink(eventId)
        .then(link => setGoogleCalendarLink(link))

    const { day, month, fullYear, hours, minutes } = getDateComponents(event?.date ?? 0)

    return (
        <>
            {isSuccess && (
                <AnimatePresence exitBeforeEnter>
                    <MessageWrapper
                        variants={messageVariants}
                        initial="exit"
                        animate="enter"
                        exit="exit"
                    >
                        <Text {...style.title}>Спасибо за оформление заявки на участие в совещании!</Text>
                        <Grid {...style.messageWrapper}>
                            <Flex
                                direction={{ base: 'column', md: 'row' }}
                                alignItems={{ base: 'flex-start', md: 'center' }}
                            >
                                <Text {...style.eventTitle}>{event.title}</Text>
                                <Text {...style.eventDate}>{day}.{month}.{fullYear}<br/>{hours}:{minutes}</Text>
                            </Flex>
                            <Divider borderColor="blue" opacity={0.3}/>
                            <Text
                                dangerouslySetInnerHTML={{ __html: event.agenda.replace(/[\r\n]/g, '<br>') }}
                            />
                            {!!event.place && (
                                <Text {...style.eventLink}>
                                    <b>Пройдет в:</b>
                                    <br/>
                                    {event.place}
                                </Text>
                            )}
                            {!!event.link && (
                                <Text {...style.eventLink}>
                                    <b>Ссылка для подключения:</b>
                                    <br/>
                                    {event.link}
                                </Text>
                            )}
                            <Box
                                as="a"
                                href={googleCalendarLink}
                                target="_blank"
                                rel="noreferrer"
                                d="block"
                                width="100%"
                            >
                                <Button
                                    w="100%"
                                    leftIcon={<CalendarIcon/>}
                                >
                                    Добавить в Google Календарь
                                </Button>
                            </Box>
                        </Grid>
                    </MessageWrapper>
                </AnimatePresence>
            )}
        </>
    )
}

const style = {
    title: {
        as: 'h2',
        color: 'blue',
        textAlign: 'center'
    },
    messageWrapper: {
        templateColumns: '1fr',
        gap: { base: '20px', md: '30px' },
        mt: '45px',
        bg: 'rgba(0,0,0,0.03)',
        borderRadius: 'base',
        p: { base: '20px', md: '30px' },
    },
    eventTitle: {
        fontSize: '1.2rem',
        lineHeight: 1.2,
        fontWeight: 600,
        mr: { base: 0, md: '30px' },
        mb: { base: '10px', md: 0 }
    },
    eventDate: {
        whiteSpace: 'nowrap',
        ml: { base: 0, md: 'auto' },
        textAlign: { base: 'left', md: 'right' }
    },
    eventLink: {
        wordBreak: 'break-all'
    }
}

export default RegistrationSuccessMessage
