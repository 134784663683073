import style from './LoadingSpinner.module.scss'

const LoadingSpinner = ({color = 'black', size = '45px'}) => {
    return (
        <div className={style['lds-default']} style={{color: color, width: size, height: size}}>
            <div className={style['lds-wrapper']} style={{transform: `scale(${parseInt(size) / 80})`}}>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    )
}

export default LoadingSpinner
