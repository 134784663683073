function getDateComponents (timestamp, padStart = true) {
    const date = new Date(+timestamp)

    const components = {
        day: date.getDate(),
        month: date.getMonth() + 1,
        fullYear: date.getFullYear(),
        year: +date.getFullYear().toString().slice(2),
        seconds: date.getSeconds(),
        minutes: date.getMinutes(),
        hours: date.getHours(),
    }

    if (padStart) {
        Object.entries(components).forEach(([key, value]) => {
            if (!['year', 'fullYear'].includes(key)) {
                components[key] = value.toString().padStart(2, '0')
            }
        })
    }

    return components
}

export default getDateComponents
