import { Box, Flex, Grid, Text } from '@chakra-ui/react'
import getDateComponents from '../helpers/getDateComponents'
import Button from './Button'

function EventCard ({ date, title, description, buttonText = 'Получить ссылку', buttonAction }) {
    const { day, month, hours, minutes } = getDateComponents(date)

    return (
        <Grid {...style.card}>
            <Flex {...style.dateWrapper}>
                <Text {...style.date}>{day}.{month}</Text>
                <Box {...style.dateDelimiter} />
                <Text {...style.time}>{hours}:{minutes}</Text>
            </Flex>
            <Box {...style.contentWrapper}>
                <Text {...style.title}>{title}</Text>
                <Text>{description}</Text>
            </Box>
            <Box

            >
                <Button
                    onClick={() => buttonAction?.()}
                    w={{ base: '100%', lg: 'auto' }}
                >
                    {buttonText}
                </Button>
            </Box>
        </Grid>
    )
}

const style = {
    card: {
        templateColumns: { base: '1fr', lg: 'max-content 1fr max-content' },
        px: { base: 0, lg: '40px' },
        py: { base: 0, lg: '25px' },
        boxShadow: '0px 8px 20px rgba(0,0,0,0.07)',
        gap: { base: '25px', lg: '40px' },
        alignItems: { base: 'flex-start', lg: 'center' },
        bg: 'white',
        borderRadius: 'base',
        overflow: 'hidden'
    },
    dateWrapper: {
        direction: { base: 'row', lg: 'column' },
        alignItems: 'center',
        pr: { base: '20px', lg: '30px' },
        pos: 'relative',
        pt: { base: '20px', lg: 0 },
        pl: { base: '20px', lg: 0 }
    },
    date: {
        mb: { base: 0, lg: '10px' },
        fontSize: { base: '1rem', lg: '1.7rem' },
        lineHeight: 1,
        color: 'darkBlue'
    },
    time: {
        color: 'gray3',
        fontSize: { base: '1rem', lg: '1.15rem' },
        lineHeight: 1
    },
    dateDelimiter: {
        pos: { base: 'static', lg: 'absolute' },
        mx: { base: '10px', lg: 0 },
        d: 'block',
        top: { base: 0, lg: '50%' },
        right: 0,
        transform: { base: 'none', lg: 'translateY(-50%)' },
        w: { base: '1px', lg: '2px' },
        h: { base: 'auto', lg: '40%' },
        alignSelf: 'stretch',
        bg: 'darkBlue'
    },
    contentWrapper: {
        px: { base: '20px', lg: 0 }
    },
    title: {
        fontSize: { base: '1.2rem', md: '1.5rem' },
        fontWeight: 600,
        mb: '10px'
    }
}

export default EventCard
