import { Box, Text } from '@chakra-ui/react'

const Header = ({ title = 'Совещания в Департаменте транспорта', children }) => {
    return (
        <Box {...style.header}>
            <Box
                overflow="hidden"
                transformOrigin="top"
                layerStyle="content"
            >
                <Text as="h1" color="white">{title}</Text>
                {!!children && (
                    <Box {...style.children}>{children}</Box>
                )}

            </Box>
        </Box>
    )
}

const style = {
    header: {
        as: 'header',
        bg: 'darkBlue',
        borderRadius: { base: '0', md: '0 0 30px 30px', lg: '0 0 55px 55px', xl: '0 0 85px 85px' },
        py: '40px'
    },
    children: {
        as: 'section',
        mt: { base: '80px', md: '100px', xl: '146px' }
    }
}

export default Header
