function AddIcon (props) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 12C0 5.372 5.372 0 12 0s12 5.372 12 12-5.372 12-12 12S0 18.628 0 12zm12-9.6a9.6 9.6 0 100 19.2 9.6 9.6 0 000-19.2z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.2 6a1.2 1.2 0 10-2.4 0v4.8H6a1.2 1.2 0 100 2.4h4.8V18a1.2 1.2 0 102.4 0v-4.8H18a1.2 1.2 0 100-2.4h-4.8V6z"
                fill="currentColor"
            />
        </svg>
    )
}

export default AddIcon
