import './App.scss'
import { Route, Switch } from 'react-router-dom'
import { IndexPage } from './pages/IndexPage'
import { RegistrationPage } from './pages/RegistrationPage'
import { AnimatePresence } from 'framer-motion'
import axios from 'axios'
import { Box, useToast } from '@chakra-ui/react'

function App () {
    const toast = useToast()

    axios.interceptors.response.use(
        response => {
            return response
        },
        reject => {
            const { response } = reject

            toast({
                position: 'top-right',
                status: 'error',
                title: response?.data ? response?.data?.error?.message : reject,
                duration: 5000,
                render: () => (
                    <Box color="white" p={'13px'} bg="#e53e3e" borderRadius={'7px'}>
                        {response?.data?.error?.message}
                    </Box>
                )
            })
        },
    )

    return (
        <div className="App">
            <Route
                render={({ location }) => (
                    <AnimatePresence exitBeforeEnter={true}>
                        <Switch location={location} key={location.pathname}>
                            <Route path="/" exact component={IndexPage}/>
                            <Route path="/registration/:id" component={RegistrationPage}/>
                            <Route path="*">404</Route>
                        </Switch>
                    </AnimatePresence>
                )}
            />
        </div>
    )
}

export default App
