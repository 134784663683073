function getTodayNextEvents(events, dateField = 'date') {
    const today = []
    const next = []

    events
        .sort((a, b) => {
            if (a[dateField] > b[dateField]) return 1;
            if (a[dateField] < b[dateField]) return -1;
            return 0
        })
        .forEach(item => {
            const date = new Date(+item[dateField]);
            const todayStart = new Date();
            todayStart.setHours(0);
            todayStart.setMinutes(0);
            todayStart.setSeconds(0);

            const todayEnd = new Date();
            todayEnd.setHours(23);
            todayEnd.setMinutes(59);
            todayEnd.setSeconds(59);

            if (date >= todayStart && date <= todayEnd) {
                today.push(item);
            } else if (date > todayEnd) {
                next.push(item);
            }
        });

    return [today, next]
}

export default getTodayNextEvents
