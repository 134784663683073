import { useField } from 'formik'
import { Box } from '@chakra-ui/react'

export const withFormikField = (Component) => {
    return function WithFormikField (props) {
        const [field, meta, helpers] = useField(props)
        WithFormikField.displayName = `WithFormikField(${Component.displayName || Component.name})`

        return (
            <Box>
                <Component {...field} {...props} />
                {meta.touched && meta.error ? (
                    <Box textStyle="formErrorMessage">{meta.error}</Box>
                ) : null}
            </Box>
        )
    }
}
