import { API } from '../API'
import { google } from 'calendar-link'

async function createGoogleCalendarEventLink (eventId) {
    try {
        const { title, date, place, agenda, organizer, link } = await API.getEvent(eventId)

        return google({
            title: title,
            start: date,
            duration: [1, 'hour'],
            location: place,
            description: (`<p>${agenda?.replace(/\n/g, '<br>')}</p><p><b>Организатор:</b><br>${organizer}</p>${link ? `<p><b>Данные для подключения:</b><br>${link}</p>` : ''}`).replace(/[\r\n]/g, '')
        })
    } catch (e) {
        return '/'
    }

}

export default createGoogleCalendarEventLink
