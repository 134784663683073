import { InputField } from './InputField'

function OrganizationField (props) {
    return (
        <>
            <InputField list="organizations" {...props} />
            <datalist id="organizations">
                <option value="Департамент транспорта" />
                <option value="Департамент строительства " />
                <option value="Москомархитектура" />
                <option value="АО «Мосинжпроект»" />
                <option value="ГКУ «УДМС»" />
                <option value="ГАУ «Институт Генплана Москвы»" />
                <option value="ГБУ «МосТрансПроект»" />
                <option value="ГКУ «Организатор перевозок»" />
                <option value="ГУП «Московский метрополитен»" />
                <option value="ГКУ ЦОДД" />
                <option value="ГУП «Мосгортранс»" />
                <option value="ГКУ «АМПП»" />
            </datalist>
        </>
    )
}

export default OrganizationField
