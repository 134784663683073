import { Box } from '@chakra-ui/react'

export const Footer = () => {
    return (
        <Box
            as="footer"
            h={{ base: '40px', md: '70px', lg: '80px' }}
        />
    )
}
