import EventCard from './EventCard'
import { useHistory } from 'react-router-dom'
import { Grid } from '@chakra-ui/react'

export const EventsTable = ({ data }) => {
    const history = useHistory()

    return (
        <Grid
            as="section"
            templateColumns="1fr"
            gap="20px"
        >
            {data.map(item => (
                <EventCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    description={item.agenda}
                    buttonAction={() => history.push(`/registration/${item.id}`)}
                />
            ))}
        </Grid>
    )

}
